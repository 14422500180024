import Link from "next/link";
import { FC } from "react";

const Hero: FC = () => {
	return (
		<section className="section-one">
			<video width="auto" height="auto" autoPlay muted id="myVideo">
				<source src="/video/hd-video-small.mp4" type="video/mp4" />
			</video>
			<div className="section-background-image-overlay"></div>
			<div className="container">
				<div className="text-holder position-relative">
					<h1>
						<span
							className="hero-text first"
							data-aos="fade-in"
							data-aos-duration="750"
							data-aos-delay="400"
							data-aos-once="true"
						>
							Honest.
						</span>
						<span
							className="hero-text second"
							data-aos="fade-in"
							data-aos-duration="750"
							data-aos-delay="1000"
							data-aos-once="true"
						>
							{" "}
							Consistent.
						</span>
						<span
							className="hero-text third"
							data-aos="fade-in"
							data-aos-duration="750"
							data-aos-delay="1600"
							data-aos-once="true"
						>
							{" "}
							Value-Focused.
						</span>
					</h1>

					<h4 className="hero-subtitle mob-only">
						<span
							className="hero-subtext first"
							data-aos="fade-in"
							data-aos-duration="750"
							data-aos-delay="2300"
							data-aos-once="true"
						>
							Wholesale merchandise for <br />
							liquidation warriors
						</span>
					</h4>
					<Link href="/inventory">
						<a className="hero-btn">Browse Truckloads Now!</a>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default Hero;
